<template>
    <div>
        <v-row>
            <v-col cols="10">
                <div v-if="title" class="form-info">
                    <v-btn @click="back" class="back-arrow" fab elevation="0" x-small>
                        <img src="@/assets/img/arrow-back.svg" alt="">
                    </v-btn>

                    <p class="text-center main-title">
                        {{title}}
                    </p>
                </div>
            </v-col>
            <v-col cols="2">
                <a
                    v-if="$root.hasAuth"
                    href="/"
                    @click="exit"
                    class="purple--text main-text d-flex align-center justify-end mt-1"
                    >
                    <img src="@/assets/img/exit.svg" alt="">
                    <span class="ml-1">Выйти</span>
                </a>
            </v-col>
        </v-row>

        <div>
            <transition name="show-fade" mode="out-in">
                <component :settings="formSettings" :is="forms[formValue]" />
            </transition>
        </div>

        <div v-if="$root.incomingData.theme === 'purple'"  class="bottom-logo">
            <img src="@/assets/img/pieceByPiece-logo.png" alt="">
        </div>

        <div v-else-if="$root.incomingData.theme === 'red'"  class="bottom-logo">
            <img src="@/assets/img/leto.svg" alt="">
        </div>

        <div v-else class="bottom-logo">
            <img src="@/assets/img/paylate.svg" alt="">
        </div>
    </div>
</template>

<script>
import Auth from "@/components/forms/Auth.vue";
import Main from "@/components/forms/Main.vue";
import SinglePurchase from "@/components/forms/SinglePurchase.vue";
import SeveralPurchases from "@/components/forms/SeveralPurchases.vue";
import RepaidLoans from "@/components/forms/RepaidLoans.vue";
import OrderInfo from "@/components/forms/OrderInfo.vue";
import PaymentShedule from "@/components/forms/PaymentShedule.vue";
import ChangePaymentDate from "@/components/forms/ChangePaymentDate.vue";
import PaymentMethods from "@/components/forms/PaymentMethods.vue";
import ChoiceCard from "@/components/forms/ChoiceCard.vue";
import SmsPage from "@/components/forms/SmsPage.vue";
import Documents from "@/components/forms/Documents.vue";
import Preloader from "@/components/forms/Preloader.vue";

export default {
    props: {
        value: String,
        title: String,
        settings: Object,
    },
    computed: {
        formValue() {
            return this.value;
        },
        formSettings() {
            return this.$root.forms.settings;
        },
    },

    methods: {
        back() {
            this.$root.steps.setStep(this.$root.$forms.prevPage)
        },
        exit() {
            localStorage.clear();
        }
    },

    data: () => ({
        forms: {
            Auth,
            Main,
            SinglePurchase,
            SeveralPurchases,
            RepaidLoans,
            OrderInfo,
            PaymentShedule,
            ChangePaymentDate,
            PaymentMethods,
            ChoiceCard,
            SmsPage,
            Documents,
            Preloader,
        },
    }),
};
</script>
