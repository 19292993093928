<template>
  <v-expansion-panels>
    <v-expansion-panel class="spoiler-panel">
      <v-expansion-panel-header class="header-block">
        <div>
          <h5 class="block-title"> {{ items.amount }} платежа</h5>
          <p class="second-text">{{ items.monthlyPayment }} руб. сегодня и ежемесячно</p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <Table class="main-table" :items="items.payments"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Table from "@/components/Table";

export default {
  components: {Table},
  props: {
    items: [Array, Object],
    accordion: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="scss" scoped>
.spoiler-panel {
  .header-block {
    padding: 0 !important;
    min-height: 0;
    padding-bottom: 16px !important;
  }

  &.v-expansion-panel--active {
    margin-top: 0px;
    padding-bottom: 16px;

    .header-block {
      min-height: 0;
    }
  }
}
</style>