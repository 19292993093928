<template>
    <v-form ref="form" class="auth">
        <h2 class="big-title text-center">
            {{ isRecovery ? 'Восстановление пароля' : 'Авторизация' }}
        </h2>

        <Field class="mt-8" anketaName="phone"/>
        <Field class="mt-4" :anketaName="isRecovery ? 'birthdate' : 'password'"/>
        <p class="error-text mt-3">{{error}}</p>

        <p @click="isRecovery = !isRecovery" class="main-text blue-text text-right mt-4">

            {{isRecovery ? 'Вернуться к авторизации' : 'Не помню пароль'}}
        </p>

        <v-btn
            :loading="loading"
            :color="$root.incomingData.theme"
            @click="action"
            class="main-btn mt-6">
            {{ isRecovery ? 'Восстановить пароль' : 'Вход' }}
        </v-btn>

    </v-form>
</template>

<script>
import Field from "@/components/Field";

export default {
    components: {Field},
    data: () => ({
        loading: false,
        error: '',
        isRecovery: false
    }),
    methods: {
        auth() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                localStorage.setItem("phone", this.$anketa.phone.value);
                localStorage.setItem("passwordHash", this.$anketa.password.hashValue);

                this.$connector.getToken().then((data) => {
                    if (data.apisetup.status === "done") {

                        this.$root.hasAuth = true;

                        localStorage.setItem("token", data.apisetup.token);
                        localStorage.setItem("bnplParentId", data.apisetup.parent_id);

                        this.$connector.accountInfo().then((data) => {
                            let applications = data.apiCustAccInfo[0].applications;

                            for (let i = 0; i < applications.length; i++) {
                                let array = applications[i];

                                if (array.assignment !== 4 && this.$root.appType === 5 || array.assignment === 4 && this.$root.appType !== 5) {
                                    applications.splice(i, 1);
                                    i--;
                                    continue;
                                }
                                this.$root.incomingData.allAmountPurchase += array.amountPurchase;
                                this.$root.incomingData.allRemainsPay += array.debt[0].principal + array.debt[0].percentage + array.debt[0].fine + array.debt[0].feeOverdue;
                                this.$root.incomingData.nearNextPay.date = array.nextPay[0].date;
                                this.$root.incomingData.nearNextPay.amount = array.nextPay[0].amount;

                                if(array.earlyRedemption === 0) {
                                    this.$root.incomingData.earlyRedemption = true;
                                }


                                let cardType = 'Mir';
                                let cardNumber = array.pan.split('');

                                if (cardNumber[0] === '4') {
                                    cardType = 'Visa';
                                } else if (cardNumber[0] === '5') {
                                    cardType = 'MasterCard';
                                }

                                if(array.pan !== '0.00') {
                                    this.$root.incomingData.cards.push({
                                        value: array.pan,
                                        visibleNumber: array.pan.replace(/(.{8})/, ""),
                                        cardType,
                                    });
                                }
                            }
                            this.$root.incomingData.applications = applications;
                            this.$root.steps.setStep('SeveralPurchases');
                        }).finally(() => this.loading = false);
                    } else {
                        this.error = "Пользователь не найден или неверный логин/пароль";
                    }
                }).finally(() => this.loading = false);
            }
        },
        recoveryPass() {
            if (this.$refs.form.validate()) {
                this.loading = true;

                this.$connector.passrecovery().then((data) => {
                    if (data[0].isSuccess) {
                        this.$root.alertSettings = {
                            text: "Пароль выслан в СМС",
                            type: "success",
                        };
                        this.isRecovery = false;
                    } else {
                        this.error = "Пользователь с указанными данными не найден";
                    }
                })
                    .finally(() => this.loading = false)
            }
        },
        action() {
            if (this.isRecovery) {
                this.recoveryPass();
            } else {
                this.auth();
            }
        },

        // changeTemplate() {
        //     if(this.isRecovery) {
        //
        //     }
        // }
    }
}
</script>

<style lang="scss">
.auth {
    display: flex;
    //align-items: center;
    justify-content: center;
    flex-direction: column;

    min-height: 100vh;
    //height: 700px;

    > div {
        width: 100%;
    }
}

.container.Auth {
    padding-top: 0;
    padding-bottom: 0;
}
</style>
