<template>
    <div>
        <v-expansion-panels class="flex-column" accordion>
            <div
                v-for="(data, index) in items"
                :key="index"
            >
                <div v-if="+data.earlyRedemption > 0" class="goods-items">
                    <v-expansion-panels>
                        <v-expansion-panel class="spoiler-panel">
                            <v-expansion-panel-header class="header-block second-title">
                                Товары
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <Goods :items="data.goods"></Goods>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <div v-if="+data.earlyRedemption > 0">
                    <div class="main-text mt-6 d-flex justify-space-between">
                        <p>
                            Списывается с карты
                            <span class="black--text">{{data.pan | card}}</span>
                        </p>
                        <p class="blue-text" @click="$root.steps.setStep('ChoiceCard', data, 'SeveralPurchases')">Изменить</p>
                    </div>

                    <h4 v-if="title === true" class="second-title mt-4">
                        {{ $root.incomingData.theme === 'purple' ? 'График оплат покупки от' : 'Покупка'}}
                         {{ data.dtClientAgreed | changeDateFormat }}
                    </h4>
                    <h4 v-if="typeof title === 'string'" class="second-title mt-8">
                        {{title}}
                    </h4>

                    <v-expansion-panel class="info-block">
                        <div class="d-flex justify-space-between align-start">
                            <div class="info-block-header">
                                <div class="info-block-img block-logo">
<!--                                    <img src="@/assets/img/percent.svg" alt="" />-->
                                    <img v-if="$root.incomingData.theme === 'purple'" src="@/assets/img/percent-purple.svg" alt="" />
                                    <img v-else src="@/assets/img/percent.svg" alt="" />
                                </div>
                                <div class="ml-5 mt-4">
                                        <p v-if="blockTitle" class="main-text">Заказ {{data.orderIdPartner}}</p>
                                        <p v-else class="main-text">Осталось оплатить</p>

                                    <h4 class="money-title">
                                        {{data.debt[0].principal + data.debt[0].percentage + data.debt[0].fine + data.debt[0].feeOverdue | money}}
                                    </h4>
                                    <!--                                    <p class="main-text">-->
                                    <!--                                        Платеж-->
                                    <!--                                        {{ data.nextPay[0].date | dateWithoutYear}}-->
                                    <!--                                        {{ data.nextPay[0].amount | money }}-->
                                    <!--                                    </p>-->
                                    <div class="d-flex justify-space-between align-center">
                                        <p class="main-text mr-3">{{data.nextPay[0].date}}</p>
                                        <p class="main-text">{{data.siteName}}</p>
                                    </div>
                                    <p class="blue-text mt-2" @click="$root.steps.setStep('SinglePurchase', data)">Подробнее</p>
                                </div>
                            </div>
                            <v-expansion-panel-header>
                            </v-expansion-panel-header>
                        </div>
                        <v-expansion-panel-content class="info-block-body pt-6">
                            <div class="pt-2">
                                <div
                                    class="d-flex justify-space-between mt-2"
                                    v-for="(schedule, i) in data.schedule"
                                    :key="i"
                                >
                                    <p :class="schedule.statusPayments == 3 && 'error-text'" class="main-text">
                                        {{ schedule.dtInsert }}
                                    </p>
                                    <p :class="schedule.statusPayments == 3 && 'error-text'" class="main-text d-flex align-center">
<!--                                        <img v-if="schedule.statusPayments == 2 && $root.incomingData.theme == 'purple'" class="mr-1" src="@/assets/img/check-icon-purple.png" alt="">-->
                                        <img v-if="schedule.statusPayments == 2" class="mr-1" src="@/assets/img/check-icon.svg" alt="">
                                        {{ schedule.amountPayment | money }}
                                    </p>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </div>
            </div>
        </v-expansion-panels>
    </div>
</template>

<script>
import money from "@/helpers/moneyFormat";
import {changeDateFormat, dateWithoutYear} from "@/helpers/Date";
import {card} from "@/helpers";
import Goods from "@/components/controls/Goods.vue";
import Spoiler from "@/components/controls/selections/Spoiler.vue";

export default  {
    components: {Spoiler, Goods},
    props: {
        items: Array,
        blockTitle: String,
        checkRedemption: Boolean,
        Renaissance: Boolean,
        title: [Boolean, String],
    },

    filters: {
        money,
        dateWithoutYear,
        card,
        changeDateFormat
    },
}
</script>

<style lang="scss">
.goods-items {
    .v-expansion-panel-content__wrap {

        border: none;
        //padding-top: 20px !important;
    }
}

</style>
