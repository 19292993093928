<template>
    <div>
        <div class="main-page">
            <p class="main-text">Осталось оплатить</p>
            <h2 class="money-title">{{ incomingData.allRemainsPay | money }}</h2>
            <p class="main-text">
                Сумма покупки: {{ incomingData.allAmountPurchase | money }}
            </p>

            <div
                v-if="incomingData.allRemainsPay !== 0 && incomingData.nearNextPay.date"
                :class="incomingData.theme === 'purple'? 'purple--text' : 'yellow yellow-block-text'"
                class="br-12 mt-6 pa-4 text-center main-text block-text"
            >
                {{ incomingData.theme === 'purple' ? 'Ближайший платеж' : 'Списание'}}
                {{ incomingData.nearNextPay.date | dateWithoutYear }}:
                {{ incomingData.nearNextPay.amount | money }}
            </div>

            <div class="main-page-info">
                <product-blocks
                    check-redemption
                    title
                    :items="incomingData.applications"
                />

                <v-btn
                    :color="incomingData.theme"
                    v-if="incomingData.earlyRedemption"
                    @click="$root.steps.setStep('RepaidLoans')"
                    class="button main-btn mt-8"
                >
                    История заказов
                </v-btn>
            </div>

            <div
                @click="$forms.open('Documents', 'Документы', 'SeveralPurchases');"
                class="block-link pointer d-flex align-center mt-6"
            >
                <div class="circle__img mr-4">
                    <img class="scaled-img" v-if="$root.incomingData.theme === 'purple'"  src="@/assets/img/docs-purple.svg" alt="" />
                    <img v-else src="@/assets/img/docs.svg" alt="" />
                </div>

                <h4 class="main-title">Документы</h4>

                <img
                    class="arrow-right"
                    src="@/assets/img/arrow-right.svg"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import money from "@/helpers/moneyFormat.js";
import { changeDateFormat, dateWithoutYear } from "@/helpers/Date";
import ProductBlocks from "@/components/ProductBlocks";

export default {
    components: { ProductBlocks },
    computed: {
        incomingData() {
            return this.$root.incomingData;
        },
    },

    filters: {
        money,
        dateWithoutYear,
        changeDateFormat,
    },
};
</script>

<style lang="scss" scoped>
.header-icon {
    padding: 12px;
    background: #e7e7e7;
    border-radius: 8px;
    display: inline-block;
}

.header-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: "SFPro", sans-serif;
    color: #848485;
    margin-left: 16px;
    margin-right: 12px;
}

.header-price {
    white-space: nowrap;
}
</style>
