<template>
    <div class="preloader-wrapper">
        <div :class="$root.incomingData.theme" class="custom-loader"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.preloader-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    align-items: center;
}

.custom-loader {
    width:50px;
    height:50px;
    border-radius:50%;
    background:conic-gradient(#fff 10%,#EB3B66);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;

    &.purple {
         background:conic-gradient(#fff 10%,#9c6cfe);
     }
}
@keyframes s3 {to{transform: rotate(1turn)}}
</style>
