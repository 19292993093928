<template>
  <v-app :class="'theme-'+ $root.incomingData.theme">
    <v-main>
      <Modal :value="activeModal"/>

      <Alert :settings="$root.alertSettings"/>

      <div :class="activeFormValue" class="container text-tr">
        <Form :title="activeFormTitle" :value="activeFormValue"/>
      </div>

      <Phone :key="$root.incomingData.theme"/>
    </v-main>
  </v-app>
</template>

<script>
import Form from "./components/Form";
import Modal from "@/components/Modal";
import Alert from "@/components/Alert";
import moment from "moment";
import Phone from "@/components/controls/Phone";

export default {
  name: "App",
  components: {
    Phone,
    Alert,
    Modal,
    Form,
  },

  mounted() {
    moment.locale('ru');

    if (this.$anketa.phone.value + '' !== localStorage.getItem('phone') || this.$anketa.password.hashValue + '' !== localStorage.getItem('passwordHash')) {
      if (!this.$anketa.phone.value) {
        this.getInfo();
      } else {
        this.$connector.getToken(this.$anketa.phone.value + '', this.$anketa.password.hashValue).then((data) => {
          localStorage.setItem("token", data.apisetup.token);
          localStorage.setItem("bnplParentId", data.apisetup.parent_id);

          localStorage.setItem("phone", this.$anketa.phone.value);
          localStorage.setItem("passwordHash", this.$anketa.password.hashValue);

          if (data.apisetup.status === "done") {
            this.getInfo();
          } else {
            this.$root.steps.setStep('Auth');
          }
        });
      }
    } else {
      this.$connector.getToken().then((data) => {
        localStorage.setItem("token", data.apisetup.token);
        localStorage.setItem("bnplParentId", data.apisetup.parent_id);

        localStorage.setItem("phone", this.$anketa.phone.value);
        localStorage.setItem("passwordHash", this.$anketa.password.hashValue);

        if (data.apisetup.status === "done") {
          this.getInfo();
        } else {
          this.$root.steps.setStep('Auth');
        }
      });
    }
  },

  methods: {
    getInfo() {
      this.$connector.accountInfo().then((data) => {
        if (data.apicustaccinfo[0].isSuccess) {

          this.$root.hasAuth = true;

          let urlParams = new URLSearchParams(window.location.search);
          let applications = data.apicustaccinfo[0].applications;

          for (let i = 0; i < applications.length; i++) {
            let array = applications[i];

            if (array.assignment !== 4 && this.$root.appType === 5 || array.assignment === 4 && this.$root.appType !== 5) {
              applications.splice(i, 1);
              i--;
              continue;
            }
            this.$root.incomingData.allAmountPurchase += array.amountPurchase;
            this.$root.incomingData.allRemainsPay += array.debt[0].principal + array.debt[0].percentage + array.debt[0].fine + array.debt[0].feeOverdue;
            if (i === 0) {
              this.$root.incomingData.nearNextPay.date = array.nextPay[0].date;
              this.$root.incomingData.nearNextPay.amount = array.nextPay[0].amount;
            }

            if (array.earlyRedemption === 0) {
              this.$root.incomingData.earlyRedemption = true;
            }
          }
          this.$root.incomingData.applications = applications;
          this.$root.steps.setStep('SeveralPurchases');

          if (urlParams.get("redirect") == 'false') {
            this.$root.steps.setStep('ChoiceCard', {
              title: urlParams.get("title"),
              appId: +urlParams.get("bnplOrderId")
            }, 'SeveralPurchases')
            this.$root.alertSettings = {
              text: 'Произошла ошибка при попытке привязать новую карту. Пожалуйста, попробуйте позже или обратитесь в техническую поддержку сервиса.',
              type: "error",
            };
          } else if (urlParams.get("redirect") === 'true') {
            this.$root.steps.setStep('ChoiceCard', {
              title: urlParams.get("title"),
              appId: +urlParams.get("bnplOrderId")
            }, 'SeveralPurchases')

            this.$root.alertSettings = {
              text: 'Новая карта успешно привязана',
              type: "success",
            };
          }
        } else {
          this.$root.steps.setStep('Auth');
        }
      })
          .catch(() => {
            this.$root.steps.setStep('Auth');
          })
    },
  },

    created() {

        let urlParams = new URLSearchParams(window.location.search);
        this.$anketa.phone.value = urlParams.get("mobilePhoneNumber");
        this.$anketa.password.hashValue = urlParams.get("passwordHash");
        let foo = document.createElement('script');

        const domains = {
            "lk.mk-mobile.online": 4,
            "lk.pochastiam.ru": 5,
            "lk.letopay.ru": 7
        }
        
        this.$root.appType = domains[window.location.hostname] || +urlParams.get("appType") || 4;
        console.log(this.$root.appType)
        console.log(domains[window.location.hostname])
        console.log(domains)
        console.log(typeof this.$root.appType)
        
        if (this.$root.appType === 5) {
            this.$root.incomingData.theme = 'purple'
            document.title = "По частям";
            document.querySelectorAll('link')[0].href = 'icons/favicon-pbp.ico';

            foo.setAttribute("src", "https://lib.usedesk.ru/secure.usedesk.ru/widget_156262_39119.js");
            foo.setAttribute("async", true)
            document.querySelector('head').appendChild(foo);
        } else if (this.$root.appType === 7) {
            this.$root.incomingData.theme = 'red';
            document.title = "Leto Pay";

            foo.setAttribute("src", "https://lib.usedesk.ru/secure.usedesk.ru/widget_156262_8742.js");
            foo.setAttribute("async", true)
            document.querySelector('head').appendChild(foo);
            document.querySelectorAll('link')[0].href = 'icons/leto.ico';
        } else if(this.$root.appType === 4) {
            this.$root.incomingData.theme = 'pink'
            document.title = "paylate";
            document.querySelectorAll('link')[0].href = 'icons/favicon.ico';
        }
    },
    computed: {
        activeFormValue() {
            return this.$root.forms.active;
        },
        activeFormTitle() {
            return this.$root.forms.title;
        },
        activeModal() {
            return this.$root.modals.active;
        },
    }
};
//  },
//};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

.theme-red {
  * {
    font-family: 'Inter', sans-serif !important;
  }

  .main-btn {
    background: linear-gradient(81.64deg, #B61351 0%, #C72721 41.15%, #E88923 77.08%, #FFEC00 100%) !important;

    &.v-btn--disabled {
      background: #E7E7E7 !important;
    }
  }
}
</style>
