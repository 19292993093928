<template>
    <div v-bind="$attrs" class="table">
      <div class="table__inner">
        <div class="table-item" :class="{first: index === 0}" v-for="(item, index) in items" :key="index">
          <p>{{ index === 0 ? 'Сегодня' : item.date }}</p>
          <p class="value">
            {{ item.value.toLocaleString('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} руб
          </p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    items: [Array, Object]
  }
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  margin-top: 16px;
  overflow: hidden;

  .table__inner {
    overflow: auto;
  }

  .table-item {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.3em;
    color: #848485;
    display: flex;
    margin: 8px 0px;

    .value {
      text-align: right;
      padding-right: 6px;
    }

    p {
      width: 100%;
      display: inline-block;
    }

    &.first {
      p {
        font-weight: 700;
        font-size: 15px;

        color: #0A0A0D;
      }
    }
  }

  &.main-table {
    background-color: #fff;

    &.closed {
      border: 0.5px solid #E7E7E7;
      padding: 8px 16px;
      border-radius: 12px;
    }

    .table__inner {
      max-height: 100px;
    }
  }
}
</style>