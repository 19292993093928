<template>
    <div>
        <label
            class="is-size-4 mb-1"
        >
            {{ label }}
        </label>
        <div class="input-wrapper input-text">
            <v-text-field
                v-mask="mask"
                v-model="fieldValue"
                :key="updateKey"
                :autofocus="autofocus"
                :placeholder="placeholder || virtualPlaceholder"
                :type="type"
                solo
                flat
                @focus="onFocus"
                :outlined="outlined"

                :hide-details="$root.isMobile ? 'auto' : false"
                validate-on-blur
                :rules="rules"
                :error.sync="hasError"

                :disabled="isDisabled"
                :autocomplete="autocomplete"
                class="mt-3"
                @keydown="onKeydown"
                @blur="onBlur"
            >
                <template #append>
                    <div
                        class="hide-icon"
                        @click="clearValue"
                        v-if="(isClear !== undefined) && value"
                    >
                        <img src="@/assets/img/clear-icon.png" alt="">
                    </div>


                    <span class="textSecondary--text">{{ appendText }}</span>
                </template>
            </v-text-field>
        </div>


        <label class="is-size-5">
            {{ comment }}
        </label>
    </div>
</template>

<script>

export default {
    props: {
        value: [Number, String, Boolean],
        label: String,
        comment: String,
        append: String,
        mask: String,
        type: String,
        rules: Array,
        placeholder: String,
        outlined: Boolean,
        // {
        //     type: Boolean,
        //     default: false
        // },
        disabled: Boolean,
        replaceEngToRu: {
            type: Boolean,
            default: true
        },
        clear: {
            type: Boolean,
            default: () => undefined
        },
        // Ограничения ввода:
        isMoney: Boolean,
        isCyrilic: Boolean,
        isCyrilicIssued: Boolean,
        isCyrilicPlace: Boolean,
        isEngEmail: Boolean,
        autocomplete: String,
    },
    computed: {
        fieldValue: {
            get() {
                if (this.isMoney && this.value) {
                    return this.value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ');
                } else {
                    return this.value;
                }
            },
            set(val) {
                let oldval = val;

                if (val != this.value) {
                    // Применяем ограничения ввода
                    val = this.applyInputRestrictions(val);

                    this.$emit('input', val);

                    if (val != oldval) {
                        // перерисовка позволяет видеть в поле ввод с ограничениями
                        this.updateKey++;
                        // autofocus позволяет мобильной клавиатуре оставаться на месте
                        // при перерисовке поля по updateKey
                        this.autofocus = true;
                    } else {
                        this.autofocus = false;
                    }
                }
            }
        },
        appendText() {
            return this.append;
        },
        virtualPlaceholder() {
            if (this.mask) {
                return this.mask.replace(/#/g, '0');
            } else {
                return null;
            }
        },
        isDisabled() {
            return this.disabled;
        }
    },
    data() {
        return {
            updateKey: 0,
            autofocus: false,
            hasError: false,
            isClear: this.clear
        }
    },
    methods: {
        applyInputRestrictions(val) {
            // let oldval = val;
            // let key;

            if (this.isMoney) {
                val = val.replace(/\D/g, '');
            }
            if (this.isEngEmail) {
                val = val.replace(/[^a-zA-Z\-0-9.@]/g, "");
            }
            if (this.isCyrilic) {
                val = val.replace(/[^а-яА-ЯёЁ -]/g, "");
            }
            if (this.isCyrilicIssued) {
                val = val.replace(/[^0-9А-яЁё/\\(№\-.,")\s]/g, "");
            }
            if (this.isCyrilicPlace) {
                val = val.replace(/[^0-9А-яЁё("\-.)\s]/g, "");
            }
            // if (this.replaceEngToRu) {
            //   key = oldval.replace(val, "");
            //   if (key.length == 1) {
            //     val += replaceToCyrilic(key);
            //   }
            // }
            return val;
        },
        clearValue() {
            // this.value = '';
            this.$emit('input', '');
        },
        onKeydown(e) {
            // У keydown плохая совместимость
            // с мобильными браузерами на сентябрь 2021
            // в event.key не приходит значение
            this.$emit('keydown', e);
        },
        onBlur(e) {
            this.$emit('blur', e);
        },

        onFocus(e) {
            this.$emit('focus', e.target.value);
        }
    }

}
</script>
